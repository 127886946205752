<template>
	<div class="input-container">
		<!-- <h2>Email Address</h2> -->
		<p>{{ languageStrings.pleaseEnterValidEmail }}</p>
		<input
			type="text"
			class="curved-border extra-padding-text-fields"
			:placeholder="languageStrings.enterEmailAddress"
			v-model="emailAddress"
		/>
		<!-- <h2>Phone number</h2> -->
		<div id="country-dropdown-container">
			{{ languageStrings.countryPhoneRegistered }}
			<CountryDropdownMenu
				v-if="countryList?.length > 0"
				:systemSettings="systemSettings"
				:playerState="playerState"
				:countryList="countryList"
				:languageStrings="languageStrings"
				:countryDropdownType="`PhoneNumber`"
			/>
		</div>
		<!-- <p>Please enter a valid phone number for verification.</p> -->
		<input type="number" class="curved-border extra-padding-text-fields" :placeholder="languageStrings.phoneNumber" v-model="phoneNumber" />
		<div class="encore-style-button-container">
			<button
				class="btn encore-style-button curved-border"
				:title="languageStrings.continueText"
				@click="submitToParent()"
			>
				{{ languageStrings.continueText }}
			</button>
		</div>
	</div>
</template>

<script>
import CountryDropdownMenu from "@/components/CountryDropdownMenu";

export default {
	name: "EmailForm",
	props: {
		systemSettings: Object,
		playerState: Object,
		countryList: Array,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		CountryDropdownMenu,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			emailAddress: "",
			phoneNumber: "",
			newAccountProperty: {
				propertyName: "",
				propertyValue: "",
				componentName: "EmailPhoneForm",
			},
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		submitToParent() {
			if (!this.emailAddress) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.emailRequired;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (!this.phoneNumber) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.phoneNumber;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			// There's probably a better way to pass data to the parent component
			// Two separate events were just the quickest way since we release in two days 07/19/24
			this.newAccountProperty.propertyName = "emailAddress";
			this.newAccountProperty.propertyValue = this.emailAddress;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.newAccountProperty.propertyName = "phoneNumber";
			this.newAccountProperty.propertyValue = this.phoneNumber;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.eventBus.emit("advanceComponent");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-container {
	display: flex;
	flex-direction: column;
	margin: 15px auto;
	padding: 15px;
	max-width: 30em;
}

input {
	font-size: 1.5em;
	border-radius: 12px;
	color: #6305c9;
}
/* .curved-border {
	height: 3.5vh;
} */

.encore-style-button-container {
	margin-top: 2vh;
}
</style>
